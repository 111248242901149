import axios from '@/libs/api.request'

export const getApplBrokenAPI = () => {
    return axios.request({
        url: 'appliance-broken',
        method: 'get'
    })
}

export const getApplBrokenByIdAPI = (id) => {
    return axios.request({
        url: 'appliance-broken/' + id,
        method: 'get'
    })
}

export const createApplBrokenAPI = (credentials) => {
    return axios.request({
        url: 'appliance-broken',
        data: credentials,
        method: 'post'
    })
}

export const deleteApplBrokenAPI = (credentials) => {
    return axios.request({
        url: 'appliance-broken/' + credentials.id,
        data: credentials,
        method: 'delete'
    })
}