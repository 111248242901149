<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນອຸປະກອນເພ</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="4">
            <CCard border-color="success">
              <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
              <CCardBody>
                <ValidationObserver v-slot="{ invalid }">
                  <CForm ref="form" :model="form" @submit.prevent="handleBroken">
                    <CRow>
                      <CCol sm="12">
                        <validation-provider rules="required">
                          <div class="form-group">
                            <label>ເລກ Serial ເຄື່ອງເພ :</label>
                            <v-select
                              prop="appl_serial_new"
                              v-model="form.appl_serial"
                              :options="applList"
                              :reduce="appl_serial => appl_serial.appl_serial"
                              label="appl_serial"
                            />
                          </div>
                        </validation-provider>
                      </CCol>
                      <CCol sm="12">
                        <validation-provider rules="required">
                          <CInput
                            label="ຈໍານວນ :"
                            type="number"
                            prop="broken_amount"
                            v-model="form.broken_amount"
                          />
                        </validation-provider>
                      </CCol>
                      <CCol sm="12">
                        <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                          <CIcon name="cil-save" />ບັນທຶກອຸປະກອນເພ
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="8">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
            <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem
                      :disabled="userGroup != 'administrator'"
                      v-on:click="onClickDelete(row.item)"
                    >ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
              </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import {
  getApplBrokenAPI,
  createApplBrokenAPI,
  deleteApplBrokenAPI,
} from "@/api/applianceBroken";
import { getApplianceAPI } from "@/api/appliance";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "BrokenAppliance",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      items: [],
      fields: [
        { key: "id", label: "ລໍາດັບ" },
        { key: "broken_date", label: "ວັນທີ່ເພ" },
        { key: "appl_serial", label: "Serial" },
        { key: "broken_amount", label: "ຈໍານວນ" },
        { key: "broken_by", label: "ຜູ້ບັນທຶກ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      form: {
        appl_serial: "",
        broken_amount: "1"
      },
      applList: [],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    // Load data  document income
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getApplBrokenAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getApplianceAPI().then((response) => {
        this.applList = response.data.data;
      });
    },

    handleBroken() {
      let credentials = {
        appl_serial: this.form.appl_serial,
        broken_amount: this.form.broken_amount,
        broken_by: decrypt(this.$store.getters.getFullName)
      };
      createApplBrokenAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
          this.form.appl_serial = "";
          this.form.broken_amount = "1";
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
    },
    onClickDelete(item) {
      let onOk = () => {
        let credentials = {
          id: item.id,
          appl_serial:item.appl_serial,
          broken_amount: item.broken_amount
        }
        deleteApplBrokenAPI(credentials)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>
